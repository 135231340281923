import { APP_HOME_PREFIXED_PATH } from '@guess-the-rose/core'
import React from 'react'
import { Flex, Heading, Text } from 'theme-ui'

import { Button } from '../../../components/Button'
import { Logo } from '../../../components/Icons'

const styles = {
  wrapper: {
    alignItems: `center`,
    flexDirection: `column`,
    bg: `#fff`,
    borderRadius: `lg`,
    size: `full`,
    p: 4,
    boxShadow: '0px 0px 8px 0 rgba(1,1,1,.05)',
    height: 'auto',
    border: '1px solid rgb(240, 240, 240)',
  },
  heading: {
    fontSize: '1.25rem',
    svg: {
      size: `icon.lg`,
      display: `block`,
      mb: 3,
    },
  },
  subheading: {
    fontWeight: `normal`,
  },
  list: {
    listStyle: `none`,
    m: '0 0 10px 0',
    p: 0,
    li: {
      p: 0,
      my: 2,
    },
    'li:before': {
      content: `""`,
      display: `inline-block`,
      width: `icon.xs`,
      bg: `text`,
      borderRadius: `full`,
      size: `7px`,
      mr: `7px`,
    },
  },
  button: {
    display: `block`,
    mt: `auto`,
  },
}

const BannerVertical = () => (
  <Flex sx={styles.wrapper}>
    <Heading sx={styles.heading} variant="h2">
      <Logo />
      Season 16 Bachelorette Fantasy Leagues now open!
    </Heading>
    <Heading sx={styles.subheading} variant="h4">
      It's free to play. To get started:
    </Heading>
    <Text as="ul" sx={styles.list} variant="small">
      <li>Create a Group or Join One</li>
      <li>Create Your Entry</li>
      <li>Invite Your Friends</li>
      <li>Make Predictions Weekly</li>
    </Text>
    <Button
      aria-label="Get Started"
      as={'a'}
      href={APP_HOME_PREFIXED_PATH}
      sx={styles.button}
    >
      Get Started
    </Button>
  </Flex>
)

export default BannerVertical
