import CardList from '@components/CardList'
import Divider from '@components/Divider'
import { useBlogCategories } from '@helpers-blog'
import { Main, Sidebar, Stack } from '@layout'
import BannerHorizontal from '@widgets/BannerHorizontal'
import BannerVertical from '@widgets/BannerVertical'
import Categories from '@widgets/Categories'
import NewsletterExpanded from '@widgets/NewsletterExpanded'
import Seo from '@widgets/Seo'
import React from 'react'

const Posts = ({
  data: { posts = {}, featuredPosts = {}, recentPosts = {} },
  ...props
}) => {
  const { pageContext: { services = {} } = {} } = props
  const categories = useBlogCategories()

  return (
    <>
      <Seo pageUrl={'blog/'} title="Blog" />
      <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
      {/* <Stack effect={false}>
        <Categories omitTitle categories={categories} variant="horizontal" />
      </Stack> */}
      <Divider style={{ marginTop: '12px', marginBottom: '12px' }} />
      <Stack effect={false}>
        <Main>
          <CardList
            fade
            omitCategory
            slider
            controlPosition="over"
            limit={3}
            loading="eager"
            nodes={featuredPosts.nodes}
            variant="horizontal-cover"
          />
          <Divider space={2} />
          <CardList
            columns={[1, 2]}
            limit={4}
            loading="eager"
            nodes={recentPosts.nodes}
            variant="horizontal-aside"
          />
        </Main>
        <Sidebar sx={{ pl: `3`, flexBasis: `1/4` }}>
          <BannerVertical />
        </Sidebar>
      </Stack>
      <Divider space={5} />
      {posts.group.length > 0 &&
        posts.group.map((group, index) => (
          <React.Fragment key={`${group.categoryName}.list`}>
            {index % 2 === 0 ? (
              <Stack
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Main>
                  <CardList
                    columns={[1, 1, 1, 3]}
                    limit={3}
                    nodes={group.nodes}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                  />
                  <Divider space={2} />
                  <CardList
                    omitMedia
                    columns={[1, 2, 3, 3]}
                    limit={3}
                    nodes={group.nodes}
                    skip={3}
                    variant={['horizontal-md', 'horizontal-aside']}
                  />
                </Main>
              </Stack>
            ) : (
              <Stack
                direction={['column', 'column', 'column', 'row']}
                title={group.categoryName}
                titleLink={group.nodes[0].category.slug}
              >
                <Sidebar
                  sx={{
                    pl: 0,
                    pr: [0, 0, 0, 3],
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <CardList
                    omitCategory
                    columns={[1]}
                    limit={1}
                    nodes={group.nodes}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                  />
                </Sidebar>
                <Main
                  sx={{
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <Divider space={2} />
                  <CardList
                    omitCategory
                    columns={[1, 1, 3, 1]}
                    limit={3}
                    mediaType="icon"
                    nodes={group.nodes}
                    skip={1}
                    variant={[
                      'horizontal-md',
                      'horizontal-md',
                      'horizontal-aside',
                    ]}
                  />
                  <Divider space={2} />
                </Main>
                <Sidebar
                  sx={{
                    pl: [0, 0, 0, 3],
                    display: [null, `flex`],
                    flexDirection: [`column`, `column`, `column`, `row`],
                  }}
                >
                  <CardList
                    omitCategory
                    columns={[1]}
                    limit={1}
                    nodes={group.nodes}
                    skip={4}
                    variant={[
                      'horizontal-md',
                      'horizontal',
                      'horizontal',
                      'vertical',
                    ]}
                  />
                </Sidebar>
              </Stack>
            )}
            {/* {index === 0 && (
              <>
                <Divider />
                <Stack effect={false}>
                  <BannerHorizontal />
                </Stack>
              </>
            )} */}
            {index !== posts.group.length - 1 && <Divider />}
          </React.Fragment>
        ))}
      <Stack>
        <Main>
          {services.mailchimp && (
            <>
              <Divider space={5} />
              <NewsletterExpanded />
            </>
          )}
        </Main>
      </Stack>
      <Divider />
    </>
  )
}

export default Posts
